import React, { useEffect, useState } from 'react';
import countries from '../db/countries.json';
import './Home.css';
import '../fonts.css';

const Home = () => {
    const [selectedCountry, setSelectedCountry] = useState('');

    // Manejo el tooltip en las banderas
    const [tooltipText, setTooltipText] = useState('');

    const handleMouseEnter = (text) => {
        setTooltipText(text);
    };

    const handleMouseLeave = () => {
        setTooltipText('');
    };


    // Manejo el idioma segun el navegador.
    const [locale, setLocale] = useState("es");

    useEffect(() => {
        if (navigator.language.startsWith('en')) {
            setLocale('en')
        } else {
            setLocale('es')
        }
    }, [])


    const translations = {
        es: {
            title: "Título en español",
            content: `
            Somos una empresa argentina fundada en el año 2002. Hemos logrado un crecimiento y evolución constante para adaptarnos a las demandas cambiantes
            del mercado. Hoy contamos con presencia regional sólida en 13 países; nuestro compromiso con la excelencia y la calidad nos ha permitido forjar relaciones duraderas tanto con los clientes como con las marcas que representamos.
            Trabajamos en estrecha colaboración con más de 60 marcas de renombre internacional. Nuestro portafolio de productos abarca desde tecnología de vanguardia hasta productos de consumo cotidiano.
            <br />
            Para más información, visita nuestro sitio web.
          `,
            visit: "Visitar sitio",
            country: "Elige tu pais"
        },
        en: {
            title: "Title in English",
            content: `
            We are an Argentinian company founded in 2002. We have achieved constant growth and evolution to adapt to the changing demands of the market. Today we have a strong regional presence in 11 countries; our commitment to excellence and quality has allowed us to forge lasting relationships with our customers and the brands we represent.
            We work in close collaboration with over 60 internationally renowned brands. Our portfolio of products ranges from cutting-edge technology to everyday consumer products.
            <br />
            For more information, visit our website.
          `,
            visit: "Visit site",
            country: "Choose your country"
        },
    };


    translations.es.content = translations.es.content.replace(/<br\s+\/>/gi, " ");
    translations.en.content = translations.en.content.replace(/<br\s+\/>/gi, " ");




    navigator.language === "es-es" && setLocale('en')


    const avatars = [
        {
            name: 'Argentina',
            url: `${process.env.PUBLIC_URL}/Banderas/argentina.png`,
            site: "https://www.solutionbox.com.ar/"
        },
        {
            name: 'United States',
            url: `${process.env.PUBLIC_URL}/Banderas/estadosunidos.png`,
            site: "https://www.solutionboxusa.com/"
        },
        {
            name: 'Uruguay',
            url: `${process.env.PUBLIC_URL}/Banderas/uruguay.png`,
            site: "https://www.solutionbox.com.uy/"
        },
        {
            name: 'Costa Rica',
            url: `${process.env.PUBLIC_URL}/Banderas/costarica.png`,
            site: "https://www.solutionbox.cr/"
        },
        {
            name: 'R. Dominicana',
            url: `${process.env.PUBLIC_URL}/Banderas/Republicadominicana.png`,
            site: "https://www.solutionbox.com.do/"
        },
        {
            name: 'Panama',
            url: `${process.env.PUBLIC_URL}/Banderas/panama.png`,
            site: "https://www.solutionbox.com.pa/"
        },
        {
            name: 'El Salvador',
            url: `${process.env.PUBLIC_URL}/Banderas/elsalvador.png`,
            site: "https://www.solutionbox.com.sv/"
        },
        {
            name: 'Guatemala',
            url: `${process.env.PUBLIC_URL}/Banderas/guatemala.png`,
            site: "https://www.solutionbox.com.gt/"
        },
        {
            name: 'Honduras',
            url: `${process.env.PUBLIC_URL}/Banderas/honduras.png`,
            site: "https://www.solutionbox.com.hn/"
        },
        {
            name: 'Hong Kong',
            url: `${process.env.PUBLIC_URL}/Banderas/hongkong.png`,
            site: "https://www.solutionbox.com.hk/"
        },
        {
            name: 'Paraguay',
            url: `${process.env.PUBLIC_URL}/Banderas/paraguay.png`,
            site: "https://www.solutionbox.com.py/"
        },
        {
            name: 'Chile',
            url: `${process.env.PUBLIC_URL}/Banderas/chile.png`,
            site: "https://www.solutionbox.cl/"
        },
        {
            name: 'Jamaica',
            url: `${process.env.PUBLIC_URL}/Banderas/jamaica.webp`,
            site: "https://www.solutionboxusa.com/"
        },

    ]

    const handleVisitSite = () => {
        const country = countries.countries.find((c) => c.name === selectedCountry);
        if (country) {
            window.open(country.url, '_blank');
        }
    };



    return (
        <div className='container'>
            <div className='background'></div>
            <div className='content'>
                <div className='logoContainer'>
                    <img
                        src="https://raw.githubusercontent.com/RamiroSB/imagenes/main/LogoSB.png"
                        alt="Logo"
                        className='logo'
                    />
                </div>
                <br />
                <br />
                <p className='paragraph' style={{ fontFamily: "Gotham" }}>
                    {translations[locale].content}
                </p>

                <br />
                <br />
                <div className='optionContainer'>
                    <div className='selectContainer'>
                        <select onChange={(e) => setSelectedCountry(e.target.value)}
                            value={selectedCountry} className='select'>
                            {countries.countries.map((country) => (
                                <option key={country.name} value={country.name}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='buttonContainer'>
                        <button onClick={handleVisitSite} className='button'>{translations[locale].visit}</button>
                    </div>
                </div>

                <div className='imageContainer'>
                    {avatars.map((avatar) => (
                        <div key={avatar.name} className="avatar-container" onMouseEnter={() => handleMouseEnter(avatar.name)} onMouseLeave={handleMouseLeave}>
                            <img
                                name={avatar.name}
                                src={avatar.url} alt="Bandera 1"
                                className='flag'
                                onClick={() => {
                                    window.open(avatar.site, "_blank");
                                }}
                            />
                            <div className="tooltip" style={{ display: avatar.name === tooltipText ? "block" : "none" }}>
                                {avatar.name}
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
};



export default Home;
